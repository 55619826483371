// extracted by mini-css-extract-plugin
export var container = "styles-module--container--1l4Tl";
export var cartTitle = "styles-module--cartTitle--1J-_O";
export var cart = "styles-module--cart--20mKF";
export var summary = "styles-module--summary--3KSel";
export var ticketHeader = "styles-module--ticketHeader--20ac2";
export var cartID = "styles-module--cartID--qKkfF";
export var label = "styles-module--label--3XXT2";
export var value = "styles-module--value--sy31v";
export var saveCart = "styles-module--saveCart--K9aKI";
export var deskTicketHeader = "styles-module--deskTicketHeader--A2O26";
export var saveModal = "styles-module--saveModal--2yNbq";
export var saveTitle = "styles-module--saveTitle--w0mOF";
export var fullInput = "styles-module--fullInput--20zjC";
export var saveCTA = "styles-module--saveCTA--Rlmbl";
export var save = "styles-module--save--UlR8v";
export var secured = "styles-module--secured--1U4dB";
export var products = "styles-module--products--3W_yI";
export var productHeader = "styles-module--productHeader--T_Der";
export var product = "styles-module--product--12-G2";
export var productDetails = "styles-module--productDetails--30NHi";
export var photo = "styles-module--photo--kEd-j";
export var mobileTicketHeader = "styles-module--mobileTicketHeader--BF6mQ";