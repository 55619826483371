import React, { useEffect, useState, useRef } from "react";
import { LocaleAsString } from "../../localeEngine";
import { navigate } from "gatsby";
import axios from "axios";
import Layout from "../../components/layout";
import SecuredPayBy from "../../components/checkout/securedPayBy";
import { Container, Typography } from "../../components/utils";
import Summary from "../../components/checkout/summary";
import Modal from "../../components/modal";
import Icon from "../../components/icons";
import ProductList from "../../components/checkout/productList";
import RemoveFromCartModal from "../../components/removeFromCartModal";
import {
  getInAppPurchase,
  getSession,
  setSession,
} from "../../utils/localStorage";
import { CONNECT, CONNECT_CLIENT } from "../../utils/definitions";
import { gtmFunction } from "../../utils/gtm";
import * as styles from "./styles.module.scss";

const doPayment = () => {
  if (getSession().customer) {
    navigate("/checkout", {
      replace: true,
      state: { step: 2 },
    });
  } else {
    navigate("/checkout", { replace: true });
  }
};

const goBack = (location) => {
  if (location && location.state && location.state.prevLocation) {
    return navigate(location.state.prevLocation);
  }
  navigate("/");
};

const SaveCart = ({ session }) => {
  const [saveModal, setSaveModal] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  return (
    <div className={styles.ticketHeader}>
      <div className={styles.cartID}>
        <Typography
          component={"span"}
          variant="h400-medium"
          className={styles.label}
        >
          Ordre ID
        </Typography>
        <Typography
          component={"p"}
          variant="h400-heavy"
          className={styles.value}
        >
          {session && session.id ? session.id : "--"}
        </Typography>
      </div>
      <div onClick={() => setSaveModal(true)} className={styles.saveCart}>
        <Icon icon="DownloadButton" size="Small" />
        <Typography component={"span"} variant="h400-heavy">
          Lagre ordre
        </Typography>
      </div>
      {saveModal && (
        <Modal closeFn={() => setSaveModal(false)}>
          <div className={styles.saveModal}>
            {/*
            <Typography
            component="p"
            variant="p-heavy"
            className={styles.saveTitle}
            >
            Lagre ordre
            </Typography>
            <Input
            className={styles.fullInput}
            label="Fornavn"
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            />
            <Input
            className={styles.fullInput}
            label="Etternavn"
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            />
            <Input
            className={styles.fullInput}
            label="E-post"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            />
          */}
            <Typography
              component="p"
              variant="p-heavy"
              className={styles.saveTitle}
            >
              Kommer snart
            </Typography>
          </div>

          <div className={styles.saveCTA}>
            <span
              role="button"
              tabIndex={0}
              onClick={() => setSaveModal(false)}
              onKeyPress={() => setSaveModal(false)}
            >
              <Typography
                component={"p"}
                variant={"p-heavy"}
                className={styles.save}
              >
                lagre
              </Typography>
            </span>
          </div>
        </Modal>
      )}
    </div>
  );
};

const useWindowUnloadEffect = (handler, callOnCleanup = true) => {
  const cb = useRef();

  cb.current = handler;

  useEffect(() => {
    const handler = () => cb.current();

    window.addEventListener("beforeunload", handler);

    return () => {
      if (callOnCleanup) handler();

      window.removeEventListener("beforeunload", handler);
    };
  }, [callOnCleanup]);
};

const Cart = ({ location }) => {
  const cb = useRef();

  const [productToDelete, setProductToDelete] = useState({});
  const [deleteModal, toggleDeleteModal] = useState(undefined);
  const [loadingDelete, setLoadingDelete] = useState(undefined);
  const [productsToUpdate, setProductsToUpdate] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [trueSession, setTrueSession] = useState(false);

  let session = getSession();
  if (typeof session === "undefined") {
    session = {
      products: [],
    };
  }

  useEffect(() => {
    const products = session.products;
    setProducts(products);
  }, []);

  const updateQuantity = (data) => {
    // we set in a new variable the products of the cart
    let newProducts = [...products];
    // then, we start a new variable to keep saving those products that have a modification in their quantities,
    // because later, when we will be going to the checkout, we're gonna update their quantities on the API.
    let newProductsToUpdate = productsToUpdate;
    newProducts.forEach((product, productIndex) => {
      // we find the upcoming product inside our cart
      if (product.itemId === data.itemId) {
        // then we check if we have a bundleId in the params
        // so we can know if the quantity update has an effect on the product or on the bundle of that product
        if (data.bundleId) {
          if (product.bundleProducts) {
            product.bundleProducts.forEach((bundle, bundleIndex) => {
              if (bundle.id === data.bundleId) {
                newProducts[productIndex].bundleProducts[bundleIndex].quantity =
                  data.quantity;

                // we check if this bundle already had it's quantity modified
                // if that's the case, we don't need to push this bundle into the array, only replace it's quantity
                let newBundleToUpdate = newProductsToUpdate.find(
                  (newProduct) => {
                    return newProduct.bundleId === data.bundleId;
                  }
                );
                if (newBundleToUpdate) {
                  let positionOfNewBundleToUpdate =
                    newProductsToUpdate.indexOf(newBundleToUpdate);
                  newProductsToUpdate[positionOfNewBundleToUpdate].quantity =
                    data.quantity;
                } else {
                  newProductsToUpdate.push(data);
                }
              }
            });
          }
        } else {
          // if we're deleting the main product (not a bundle)
          // we just take it out from the array so it doesn't show up

          if (data.quantity === 0) {
            newProducts.splice(productIndex, 1);
          } else {
            if (data.quantity < product.quantity) {
              // we have to update the quantities of the bundles
              // if we're lowering the quantity of the main product
              if (product.bundleProducts) {
                product.bundleProducts.forEach((bundle, bundleIndex) => {
                  if (bundle.quantity > bundle.maxQuantity * data.quantity) {
                    newProducts[productIndex].bundleProducts[
                      bundleIndex
                    ].quantity = bundle.maxQuantity;

                    let newBundleToUpdate = newProductsToUpdate.find(
                      (newProduct) => {
                        return newProduct.bundleId === bundle.id;
                      }
                    );
                    if (newBundleToUpdate) {
                      let positionOfNewBundleToUpdate =
                        newProductsToUpdate.indexOf(newBundleToUpdate);
                      newProductsToUpdate[
                        positionOfNewBundleToUpdate
                      ].quantity = bundle.maxQuantity * data.quantity;
                    } else {
                      newProductsToUpdate.push({
                        itemId: data.itemId,
                        quantity: bundle.maxQuantity * data.quantity,
                        bundleId: bundle.id,
                      });
                    }
                  }
                });
              }
            }

            newProducts[productIndex].quantity = data.quantity;
          }

          // we check if this product already had it's quantity modified
          // if that's the case, we don't need to push this product into the array, only replace it's quantity
          let newProductToUpdate = newProductsToUpdate.find((newProduct) => {
            return newProduct.itemId === data.itemId && !newProduct.bundleId;
          });
          if (newProductToUpdate) {
            let positionOfNewProductToUpdate =
              newProductsToUpdate.indexOf(newProductToUpdate);
            newProductsToUpdate[positionOfNewProductToUpdate].quantity =
              data.quantity;
          } else {
            newProductsToUpdate.push(data);
          }
        }
      }
    });

    setProducts(newProducts);
    setTrueSession(getSession());
    setSession({
      ...getSession(),
      products: newProducts,
    });
    toggleDeleteModal(false);
    setProductsToUpdate(newProductsToUpdate);
  };

  const sendNewQuantityToApi = () => {
    if (productsToUpdate && productsToUpdate.length > 0) {
      setLoading(true);
      axios
        .post(`${CONNECT}/checkout/update-items`, {
          client: CONNECT_CLIENT,
          session: getSession().uuid,
          items: productsToUpdate,
        })
        .catch((e) => console.log("ERROR", e))
        .then((response) => {
          if (response && response.data && response.data.id) {
            gtmFunction("add_to_cart", productsToUpdate);
            setSession({
              ...getSession(),
              timeStamp: new Date().getTime(),
              products: response.data.products,
            });
            setTrueSession(false);
            doPayment();
          }
        });
    } else {
      doPayment();
    }
  };

  const deleteFromCart = () => {
    toggleDeleteModal(false);
    setLoadingDelete(productToDelete.itemId);
    axios
      .post(`${CONNECT}/checkout/remove-from-cart`, {
        client: CONNECT_CLIENT,
        session: getSession().uuid,
        product: productToDelete && productToDelete.itemId,
      })
      .then((res) => {
        toggleDeleteModal(false);
        setLoadingDelete(undefined);
        let newProducts = products.filter(
          (prod) => prod.itemId !== productToDelete.itemId
        );
        setProducts(newProducts);
        if (res.data.products) {
          setSession({
            ...res.data,
            products: newProducts,
          });
          if (trueSession) {
            setTrueSession(res.data);
          }
          setSession(res.data);
        }
        gtmFunction("remove_from_cart", [
          {
            ...productToDelete,
            supplier: productToDelete.attributeList.find(
              (atr) => atr.attribute === "supplier"
            )?.value,
          },
        ]);
      });
  };

  const recoverTrueCart = () => {
    if (trueSession !== false) {
      setSession(trueSession);
    }
  };

  const setDeleteModal = (product) => {
    toggleDeleteModal(true);
    setProductToDelete(product);
  };

  useWindowUnloadEffect(() => recoverTrueCart());
  return (
    <Layout
      metatags={{
        title: LocaleAsString({ id: "cart.header.my_shopping_cart" }),
      }}
      hideMobileFooter={true}
      backgroundColor={"#f4f4f9"}
    >
      <Container
        component={"section"}
        maxWidth={"lg"}
        className={styles.container}
      >
        <div className={styles.cartTitle}>
          <Typography variant="h900-heavy" component={"h1"}>
            <LocaleAsString id="cart.header.my_shopping_cart" />
          </Typography>
        </div>
        <Container component={"div"} maxWidth={"lg"} className={styles.cart}>
          {/* <div className={styles.mobileTicketHeader}>
            <SaveCart />
          </div> */}
          <div className={styles.secured}>
            <SecuredPayBy backgroundColor="white" />
          </div>
          <div className={styles.summary}>
            <Typography variant="h400-medium" component="span">
              <LocaleAsString id="my_account.menu.order" /> #{session.id}
            </Typography>
          </div>
          <ProductList
            loadingDelete={loadingDelete}
            products={products}
            updateQuantity={updateQuantity}
            setDeleteModal={setDeleteModal}
          />
          <RemoveFromCartModal
            deleteModal={deleteModal}
            productToDelete={productToDelete}
            toggleDeleteModal={toggleDeleteModal}
            deleteFromCart={deleteFromCart}
          />
        </Container>
        <Summary
          hideBackButton={!getInAppPurchase()}
          text={
            <>
              <Typography component="span" variant="h500-heavy">
                <LocaleAsString id="cart.summary.cta" />
              </Typography>
              <Icon icon="ArrowRight" size="Small" />
            </>
          }
          loading={loading}
          cta={() => sendNewQuantityToApi()}
          secondaryCta={() => goBack(location)}
          products={products}
          disabled={products && products.length > 0 ? false : true}
        />
      </Container>
    </Layout>
  );
};

export default Cart;
